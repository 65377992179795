import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../ToolBar/toolbarstyles.css';
import logo from '../../imagens/LogoInformezzVertical.png';
import WhatsApp from '../WhatsApp';
import ModalFormularioContato from '../ModalFormularioContato';
import { SocialIcon } from 'react-social-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import PersistentDrawerRight from '../Drawer';

const generateWhatsAppUrl = (phone, message) => {
  const encodedMessage = encodeURIComponent(message);
  return `https://wa.me/${phone}?text=${encodedMessage}`;
};

const Toolbar = ({ habilitar, exibirToolbarBottom }) => {
  const [showModalContato, setShowModalContato] = useState(false);
  const [habilitarComponentes, setHabilitarComponentes] = useState(habilitar);
  const [menuVisivel, setMenuVisivel] = useState(true);
  const navigate = useNavigate();

  const toggleMenu = () => {
    if (window.innerWidth < 1200) {
      setMenuVisivel(false);
    } else {
      setMenuVisivel(true);
    }
  };

  useEffect(() => {
    toggleMenu();
    window.addEventListener('resize', toggleMenu);
    return () => {
      window.removeEventListener('resize', toggleMenu);
    };
  }, []);

  const scrollToContent = (id) => {
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: 'smooth' });
  };

  const handleCloseModalContato = () => {
    setShowModalContato(false);
  };

  const whatsappUrl = generateWhatsAppUrl("5546991107619", "Olá! Gostaria de saber mais sobre o sistema...");

  return (
    <>
      <div className='toolbar'>
        <div className="left-section">
          <img
            src={logo}
            alt="Logo"
            onClick={() => navigate('/')}
          />
        </div>

        {habilitarComponentes && menuVisivel && (
          <div className="center-section">
            <h4 onClick={() => scrollToContent("funcionalidades")}>
              Funcionalidades
            </h4>
            <h4 onClick={() => scrollToContent("sobre-nos")}>
              Segmento
            </h4>
            <h4 onClick={() => scrollToContent("sobre-nos")}>
              Sobre Nós
            </h4>
            <h4 className="fale-conosco" onClick={() => scrollToContent("fale-conosco")}>
              Fale Conosco
            </h4>
          </div>
        )}

        <div className="right-section social-icons">
          <div className="icones">
            <SocialIcon 
              network='instagram' 
              url="https://www.Instagram.com/Informezz" 
              bgColor="#0E3383"
              style={{ width: 32, height: 32 }}
            />
          </div>
          <div className="icones">
            <SocialIcon 
              network='facebook' 
              url="https://www.facebook.com/people/Informezz-Sistemas/61557268662997/?mibextid=ZbWKwL" 
              bgColor="#0E3383"
              style={{ width: 32, height: 32 }}
            />
          </div>
          <div className="icones">
            <SocialIcon 
              network='whatsapp' 
              url="https://wa.me/5546991107619" 
              bgColor="#0E3383"
              style={{ width: 32, height: 32 }}
            />
          </div>
        </div>

        {!menuVisivel && habilitarComponentes && (
          <div className='col'>
            <PersistentDrawerRight />
          </div>
        )}
      </div>
      <ModalFormularioContato show={showModalContato} onHide={handleCloseModalContato} />

      {exibirToolbarBottom && ( // Verifica se deve exibir a Toolbar-Bottom
        <div className="bottom-toolbar">
          <div className="top-row">
            <button
              className="support-button"
              onClick={() => window.open("https://wa.me/554632250131", "_blank")}
            >
              Suporte Técnico
            </button>
          </div>
          <div className="bottom-row">
            <div className="left-bottom">
              <button 
              className="bottom-button" 
              onClick={() => setShowModalContato(true)}>
                Solicite uma Demonstração
              </button>
            </div>
            <div className="right-bottom">
              <button 
              className="bottom-button whatsapp-button" 
              onClick={() => window.open(whatsappUrl, "_blank")}>
                <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon" style={{ marginRight: '16px', color: '#FFFFFF', fontSize: '32px' }} />
                WhatsApp Comercial
              </button>          
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Toolbar;
