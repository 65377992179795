import React from 'react';
import './stylesbuttonsfuncionalidades.css';

const ButtonsFuncionalidades = () => {
    const handleButtonClick = (option) => {
        const element = document.getElementById(option);
        if (element) {
            const yOffset = -200; // ajuste conforme necessário
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

    return (
        <div className="buttonfuncionalidades-container">
            <div className="title-section">
                <h1>Quer ter uma gestão mais eficiente e prática para a sua loja?</h1>
                <h4>Clique nas opções abaixo e veja como podemos lhe ajudar</h4>
            </div>
            <div className="buttons-section">
                <button className="custom-button" onClick={() => handleButtonClick('condicionais')}>
                    Gestão de Condicionais
                </button>
                <button className="custom-button" onClick={() => handleButtonClick('vendas')}>
                    Gestão de Vendas e Caixas
                </button>
                <button className="custom-button" onClick={() => handleButtonClick('financeira')}>
                    Gestão Financeira
                </button>
                <button className="custom-button" onClick={() => handleButtonClick('estoques')}>
                    Gestão de Estoques e Compras
                </button>
                <button className="custom-button" onClick={() => handleButtonClick('lives')}>
                    Gestão de Lives
                </button>
                <button className="custom-button" onClick={() => handleButtonClick('aplicativo')}>
                    App Vendedor | Gestor | IA
                </button>
                <button className="custom-button" onClick={() => handleButtonClick('ecommerce')}>
                    Integração com E-Commerce
                </button>
                <button className="custom-button" onClick={() => handleButtonClick('clientes')}>
                    Gestão de Clientes
                </button>
                <button className="custom-button" onClick={() => handleButtonClick('nota-fiscal')}>
                    Emissão de Nota Fiscal
                </button>
            </div>
        </div>
    );
};

export default ButtonsFuncionalidades;
