import React, { useState } from 'react';
import './topoprincipalstyles.css';
import logo from '../../imagens/imageComputadorTopo.png';
import ModalFormularioContato from '../ModalFormularioContato';

const TopoPrincipal = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (    
    <div className="topo-principal">
      <div className="titulo-inicial">
        <h1 className="titulo-inicial-text">
          Sistema de gestão específico para lojas de <br />
          <span className="highlight">Roupas, Calçados</span> e <span className="highlight">Acessórios</span>  
        </h1>
      </div>
      <div className="texto-botao-imagem">
        <div className="texto-botao-container">
          <h4 className="texto-esquerdo-text">
            Solução moderna e inteligente que irá simplificar a gestão da sua loja de moda.<br/>
          </h4>
          <button className="btn-conhecagratis" onClick={handleOpenModal}>CONHEÇA GRÁTIS</button>
        </div>
        <div className="imagem-direita">          
          <img src={logo} alt="Logo" className="top-image" />
        </div>
      </div>
      <div className="texto-abaixo">
        <h2 className="texto-abaixo-text">
          ERP em nuvem - <span className="highlight">Conforto</span> e <span className="highlight">Segurança</span> para seus dados
        </h2>
      </div>      
      <ModalFormularioContato show={showModal} onHide={handleCloseModal} />
    </div>
  );
}

export default TopoPrincipal;
