import React, { useLayoutEffect, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import imagecondicional from '../../imagens/imagecardcondicional.png';
import imagevenda from '../../imagens/imagecardvendas.png';
import imagefinanceiro from '../../imagens/imagecardfinanceiro.png';
import imageestoque from '../../imagens/imagecardestoque.png';
import imagelive from '../../imagens/imagecardlive.png';
import imageapp from '../../imagens/imagecardapp.png';
import imageecommerce from '../../imagens/imagecardecommerce.png';
import imageclientes from '../../imagens/imagecardclientes.png';
import imagenotafiscal from '../../imagens/imagecardnotafiscal.png';
import './styles.css?v=1.0';
import ButtonSolicitarDemonstracao from '../ButtonSolicitarDemonstracao';
import ModalFormularioContato from '../ModalFormularioContato';

export default function CardsFuncionalidades() {
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const ctx = gsap.context(() => {
      gsap.timeline({
        scrollTrigger: {
          trigger: '.card',
          start: 'top 80%',
          end: 'bottom 20%',
          scrub: true,
        }
      })
      .fromTo('.card', {
        opacity: 0,
        y: 100
      }, {
        opacity: 1,
        y: 0,
        stagger: 0.2
      });
    });

    return () => ctx.revert();
  }, []);

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const cardData = [
    {
      id: 'condicionais',
      title: 'GESTÃO DE CONDICIONAIS',
      text: 'Oferecemos a melhor opção de gestão de condicionais do mercado. Reduza riscos e impulsione seus lucros com uma solução moderna e eficaz.',
      details: [
        '- Gestão de envios, retornos e vendas.',
        '- Alerta de peças já enviadas.',
        '- Controle de limites personalizados por cliente.',
        '- Análise e históricos completos por cliente, produto e vendedor.',
      ],
      img: imagecondicional,
    },
    {
      id: 'vendas',
      title: 'GESTÃO DE VENDAS E FRENTE DE CAIXA',
      text: 'Nosso PDV foi projetado para garantir uma operação ágil, eliminando a fila de espera dos seus clientes.',
      details: [
        '- PDV intuitivo com troca e gestão de créditos integrados.',
        '- Abertura e lançamentos financeiros.',
        '- Controle de múltiplos caixas por loja.',
        '- Históricos e análises de vendas completas.',
      ],
      img: imagevenda,
    },
    {
      id: 'financeira',
      title: 'GESTÃO FINANCEIRA',
      text: 'A gestão financeira precisa ser exata e descomplicada. Oferecemos um controle financeiro completo, mantendo tudo em um só lugar e fácil de usar.',
      details: [
        '- Gestão de crediário próprio e carnê.',
        '- Controle de contas a pagar e fornecedores.',
        '- Gerenciamento de recebíveis de cartão de crédito e débito.',
        '- Fluxo de caixa e DRE.',
      ],
      img: imagefinanceiro,
    },
    {
      id: 'estoques',
      title: 'GESTÃO DE ESTOQUES E COMPRAS',
      text: 'O cadastramento completo dos seus produtos não precisa ser complicado. Simplificamos para que você obtenha toda a grade do produto com apenas um cadastro.',
      details: [
        '- Cadastro de produto por grade de tamanhos e cores com foto.',
        '- Impressão de etiquetas com código de barras.',
        '- Controle de estoques e transferências multilojas.',
        '- Análise para auxiliar nas compras e reposição de mercadoria.',
      ],
      img: imageestoque,
    },
    {
      id: 'lives',
      title: 'GESTÃO DE LIVES (LIVE SHOP)',
      text: 'Imagine poder realizar sua live e, ao mesmo tempo, adicionar os @ dos clientes diretamente no sistema! Com nosso Live Shop isso é possível.',
      details: [
        '- Adicione os @ dos clientes diretamente no produto mostrado.',
        '- Finalize as vendas sem necessidade de redigitar produtos.',
        '- Visualize quais clientes compraram cada produto e não perca a venda.',
      ],
      img: imagelive,
    },
    {
      id: 'aplicativo',
      title: 'APLICATIVO COM INTELIGÊNCIA ARTIFICIAL',
      text: 'Um aplicativo, várias funcionalidades. Aplicativo para Vendedores e Gestores',
      details: [
        '- Gestor: Veja indicadores e análises precisas em tempo real. Além de acompanhamento de metas.',
        '- Vendedor: Envios de condicionais através do app. Consulta de preços e estoques.',
        '- Inteligência Artificial: Sugere peças conforme o perfil e estilo do cliente, com base no seu estoque. Auxilia nas vendas e envios de condicionais.',
      ],
      img: imageapp,
    },
    {
      id: 'ecommerce',
      title: 'INTEGRAÇÃO COM E-COMMERCE',
      text: 'Venda na sua loja física e virtual com o estoque integrado. Reduza riscos e impulsione seus lucros com uma solução moderna e eficaz.',
      details: [
        '- Principais lojas e marktplaces do mercado da moda.',
        '- Sincronize estoque, preços e pedidos.',
        '- Emissão de venda e nota fiscal com poucos cliques.',
      ],
      img: imageecommerce,
    },
    {
      id: 'clientes',
      title: 'GESTÃO DE CLIENTES',
      text: 'Cada cliente é único. Registre informações detalhadas com facilidade em nosso sistema.',
      details: [
        '- Cadastro de manequim do cliente e seus dependentes.',
        '- Acesso a históricos de vendas e ranking de melhores clientes.',
        '- Veja quem está de aniversário para fazer ações exclusivas e fortalecer o relacionamento e aumentar a fidelização.',
        '- Analise a conversão de condicionais de cada cliente e entenda como cada um se comporta.',
      ],
      img: imageclientes,
    },
    {
      id: 'nota-fiscal',
      title: 'EMISSÃO DE NOTA FISCAL',
      text: 'Emissão de documentos fiscais muito mais rápido e automatizado.',
      details: [
        '- Emissão de NF-e e NFC-e em poucos cliques.',
        '- Possibilidade de emissão de notas por rotina: Devolução, Trocas e outros de forma simples.',
        '- Facilidade no envio dos arquivos fiscais para a contabilidade.',
      ],
      img: imagenotafiscal,
    },
  ];

  return (
    <div id="funcionalidades" className="root">
      <div className="cards-section">
        {cardData.map((card, index) => {
          const className = index % 2 === 0 ? 'card even' : 'card odd';
          return (
            <div className={className} key={index} id={card.id} style={{ border: '0px' }}>
              <div className="card-image-wrapper">
                <img src={card.img} className="card-image" alt={card.title} />
              </div>
              <div className="card-content">
                <h1 className="card-h1">{card.title}</h1>
                <h4 className="card-h4">{card.text}</h4>
                {card.details.map((detail, i) => (
                  <h5 className="card-h5" key={i}>{detail}</h5>
                ))}
                <div className="button-container" style={{backgroundColor: 'white'}}>
                  <ButtonSolicitarDemonstracao onClick={handleOpenModal}/>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <ModalFormularioContato show={showModal} onHide={handleCloseModal} />
    </div>
  );
}
