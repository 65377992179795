import React, { useCallback, useEffect } from 'react';
import { InputLabel, TextField } from '@mui/material';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import api from '../../services/api';
import { FormLabel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from '../LoadingScreen';

function ModalFormularioContato(props) {        
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [nomeloja, setNomeLoja] = useState('');
  const [celular, setCelular] = useState('');
  const [showMensagem, setShowMensagem] = useState(false);
  const [nomeError, setNomeError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [celularError, setCelularError] = useState(false);
  const [nomelojaError, setNomeLojaError] = useState(false);
  const [validarCampos, setValidarCampos] = useState(false);  
  const [enviadoComSucesso, setEnviadoComSucesso] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    LimparCampos();
  }, [isOpen]);

  useEffect(() => {
    if (props.show) {
      handleOpen(); 
    }
  }, [props.show]);

  const handleClose = () => { 
    setIsOpen(false);
    props.onHide();
    setValidarCampos(false);
  };   

  const handleOpen = () => {
    setIsOpen(true);
    LimparCampos();
  };

  const HandleClick = async() => {      
    try
     {              
      setValidarCampos(true);
      validarCamposVazios(); 
      debugger
      if (nome !== '' && email !== '' && nomeloja!== '' && celular !== '')
        {         
            setLoading(true) 
            const response = await api.post("/leads", { nome, email, celular, nomeloja }, { headers: { "Content-Type": "application/json" }});                                                       
            await api.post(`leads/enviaremail/${response.data.id}`, { headers: { "Content-Type": "application/json" }})
            setLoading(false)  
            navigate("/obrigado")     
        }
      else
      {
        setShowMensagem(true);
      }        
    } catch (error) 
    {

    }
  }


  
  function LimparCampos() {
    setNome('');
    setEmail('');
    setNomeLoja('');
    setCelular('');
    setNomeError(false);
    setEmailError(false);    
    setNomeLojaError(false);
    setCelularError(false);
  }

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove todos os não dígitos
    if (value.length <= 12) { // Limita o comprimento para 10 dígitos
      setCelular(value.replace(/(\d{0,2})(\d{0,5})(\d{0,4})/, '($1) $2-$3'));
    }    
  }; 

  const validarCamposVazios = () => {    
    if (nome === '') {
      setNomeError(true);
    } else {
      setNomeError(false);
    }
    if (email === '') {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    
    if (celular === '') {
      setCelularError(true);
    } else {
      setCelularError(false);
    }
    if (nomeloja === '') {
      setNomeLojaError(true);
    } else {
      setNomeLojaError(false);
    }
  };

  return (
    <>         
      <Modal  
      size='lg'
      centered
      {...props} >
        <Modal.Header closeButton>
          <Modal.Title style={{color: '#0E3383' }} > <h3 style={{textAlign: 'center'}}>Preencha as informações abaixo que em breve entraremos em contato com você</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <TextField
                required
                fullWidth
                id="outlined-required"
                label="Nome"
                placeholder='Nome Completo'
                variant="outlined"
                onChange={(e) => {
                  setNome(e.target.value.toUpperCase());     
                  if (validarCampos)
                  {
                    validarCamposVazios();
                  }
                }}       
                error={nomeError}         
                />
                <FormLabel style={{fontSize: '10px', color: 'red'}} hidden={!nomeError} >Campo Obrigatório</FormLabel>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
               <TextField
                required
                fullWidth
                id="outlined-required"
                label="Email"
                placeholder='seuemail@mail.com.br'
                variant="outlined"
                onChange={(e) => {
                  setEmail(e.target.value.toUpperCase());    
                  if (validarCampos)
                  {
                    validarCamposVazios();
                  }                                                     
                }}   
                error={emailError}
                />
                <FormLabel style={{fontSize: '10px', color: 'red'}} hidden={!emailError} >Campo Obrigatório</FormLabel>                
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <InputLabel />
                 <TextField
                  type="tel"
                  required
                  fullWidth
                  value={celular}
                  onChange={(e) => {
                    handleInputChange(e); 
                    if (validarCampos)
                    {
                      validarCamposVazios();
                    }}}
                  label="Celular"
                  placeholder="(DDD) 00000-0000"
                  variant="outlined"
                  error={celularError}
                />       
                 <FormLabel style={{fontSize: '10px', color: 'red'}} hidden={!celularError} >Campo Obrigatório</FormLabel>        
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
               <TextField
                required
                fullWidth
                id="outlined-required"
                label="Nome da Loja"
                placeholder='Nome da Loja'
                variant="outlined"
                onChange={(e) => {
                  setNomeLoja(e.target.value.toUpperCase());     
                  if (validarCampos)
                  {
                    validarCamposVazios();
                  }                                            
                }}  
                error={nomelojaError} 
                />
                 <FormLabel style={{fontSize: '10px', color: 'red'}} hidden={!nomelojaError} >Campo Obrigatório</FormLabel> 
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="success" 
            onClick={HandleClick}
            style={{width: '100%', backgroundColor:'#50A866',}}
            >
            ENVIAR
          </Button>   
          <FormLabel style={{fontSize: '25px', color: '#0E3383', marginRight: '50px'}} hidden={!loading} >Aguarde!</FormLabel>       
        </Modal.Footer>
      </Modal>      
    </>
  );
}

export default ModalFormularioContato;