import React from 'react';
import './styles.css'; 
import Toolbar from '../../components/ToolBar';
import Content from '../../components/Content';

const PaginaPrincipal = () => {  
    return (
    <>
        <Toolbar habilitar={true} exibirToolbarBottom={true} />       
        <Content/>
    </>
    );
}

export default PaginaPrincipal;
