import React from 'react';
import './stylesCentralDeInformacoesEtiquetas.css';
import ToolBar from '../../components/ToolBar';
import InfoAdicionais from '../../components/InfoAdicionais';
import RedesSociais from '../../components/RedesSociais';
import EtiquetasMasc from '../../components/CentralInformacoesEtiquetas';
import EntreEmContatoSuporte from '../../components/EntreEmContatoSuporte';

const CentralDeInformacoesEtiquetas = () => {
  return (
    <>
      <ToolBar habilitar={true} exibirToolbarBottom={false} />      
      <EtiquetasMasc />
      <EntreEmContatoSuporte />  
      <InfoAdicionais />      
      <RedesSociais />
    </>
  );
}

export default CentralDeInformacoesEtiquetas;
