import React from 'react';
import PropTypes from 'prop-types';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';

const ButtonVoltarHome = ({ text, onClick }) => {
    return (
        <Button 
            variant="text" 
            onClick={onClick}
            sx={{
                backgroundColor: 'transparent',
                color: '#0e3383',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                transition: 'background-color 0.3s, color 0.3s',
                paddingLeft: '16px',
                paddingRight: '16px',
                '&:hover': {
                    backgroundColor: '#0e3383',
                    color: '#ffffff',
                    '.MuiSvgIcon-root': {
                        color: '#ffffff',
                    },
                },
            }}
            startIcon={<ArrowBack sx={{ color: '#0e3383' }} />}
        >
            {text}
        </Button>
    );
};

ButtonVoltarHome.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default ButtonVoltarHome;
