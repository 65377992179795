import React, { useRef, useEffect } from 'react';
import Toolbar from '../../components/ToolBar';
import { Card, Container, Button } from '@mui/material';  // Adicionada a importação do Button
import './styles.css';

const PoliticaDePrivacidade = () => {  
    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    const voltarInicio = () => {
        window.location.href = 'https://informezz.com.br/#/home';
    };

    return (
    <>
        <Toolbar  habilitar={false}/>         
        <div ref={containerRef} style={{padding:'64px'}}>    
        </div>
        <Button 
                variant="contained" 
                color="primary" 
                onClick={voltarInicio}
                style={{ marginTop: '20px', marginBottom: '20px' }}
            >
                Voltar a Página Inicial
        </Button>
        <Container className='container' >
            <Card  style={{padding: '48px'}}>       
            <h3>Política de Privacidade (Atualizado em 19/10/2023)</h3>                             
               <label className='politica-privacidade'>   
               <h5>1. INTRODUÇÃO</h5> 
               <br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               A sua privacidade é muito importante para nós da Informezz. Estamos comprometidos em preservar sua privacidade e assumimos o compromisso de tratar 
               Dados Pessoais de acordo com a legislação aplicável e com as melhores práticas sobre privacidade e proteção de dados. 
               Somente será solicitado suas informações quando realmente for fundamental para oferecer nossos produtos ou serviços, sempre por meios legais e 
               com o seu conhecimento e consentimento. Também informamos o motivo de estarmos coletando e como será usado. Apenas retemos as informações 
               coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos seus dados, os protegemos dentro de meios comercialmente 
               aceitáveis para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados. Tais informações não serão compartilhadas 
               publicamente, exceto quando exigido por lei. Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que, talvez não possamos 
               fornecer alguns dos serviços desejados.

               <br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
               Se tiver dúvidas sobre como são tratados os dados do usuário e demais informações pessoais, entre em contato conosco pelo 
               <br/> telefone <strong>(46) 3225-0131</strong> ou E-mail: <strong>informezz@informezz.com.br.</strong>

               <br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               Nossa política de privacidade se aplica a todos os produtos e serviços fornecidos pelas empresas: INFORMEZZ SISTEMAS PARA LOJAS LTDA inscrita no 
               CNPJ n.º 45.720.244/0001-87, localizada no endereço Rua Xingu, 61 Centro de Pato Branco, PR. Entre os produtos e serviços, está o software ERP 
               para lojas de calçados e roupas, o site  
               <a href="http://www.informezz.com.br" target="_blank" rel="noopener noreferrer"> www.informezz.com.br </a> e aplicativos Mobile pertencentes a nossa empresa.

               <br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               Na sequência será apresentado a forma como ocorrerá a coleta, tratamento, armazenamento e demais afins sobre os dados fornecidos pelo usuário.

               <br/><br/>
               <h5>2. CONCEITOS E TERMINOLOGIAS UTILIZADAS</h5> 
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               Nesta política de privacidade, será utilizado as seguintes definições e terminologias:

               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <strong>Controlador: </strong>é a pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de Dados Pessoais;

               <br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <strong>Dados Pessoais: </strong>significa quaisquer informações relacionadas a uma pessoa identificada ou identificável, incluindo identificadores eletrônicos;

               <br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <strong>Dados Pessoais Sensíveis: </strong>significa Dados Pessoais sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dados referentes à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural;
                
               <br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <strong>LGPD: </strong>é a Lei nº 13.709/2018, conhecida popularmente como Lei Geral de Proteção de Dados que prevê os direitos dos Titulares dos Dados, as bases legais para o tratamento de Dados Pessoais, obrigações e demais premissas envolvendo a segurança dos Dados Pessoais;

               <br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <strong>Operador:</strong>é a pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de Dados Pessoais em nome do Controlador;

               <br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <strong>Titular de Dados Pessoais: </strong>é a pessoa natural a quem se referem os Dados Pessoais que são objeto de operações de tratamento;

               <br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <strong>Tratamento de Dados Pessoais:</strong>é qualquer operação realizada com Dados Pessoais, como as que se referem à coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.


               <br/><br/>
               <h5>3. CONSENTIMENTO POR PARTE DO USUÁRIO</h5>                 
               <br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                   O uso continuado de nosso site e serviços será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais.

               <br/><br/>
               <h5>4. A QUEM SE APLICA</h5>                 
               <br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               Esta Política aplica-se a todas as pessoas que acessarem e de qualquer outra forma utilizarem o site da Informezz e suas funcionalidades, aplicando-se também a pessoas naturais ou jurídicas que mantenham relações comerciais.

               <br/><br/>
               <h5>5. DADOS COLETADOS</h5>                 
               <br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               A Informezz poderá coletar e armazenar dados pessoais inseridos ativamente pelo usuário no site, ou dados informados pelo usuário para fins de cadastro como: nome, 
               nome da empresa, número de telefone, endereço, e-mail (profissional e/ou pessoal), cargo, CPF, RG, CNPJ, Inscrição Estadual, e demais informações que poderão ser solicitadas. 

               <br/><br/>
               <h5>6. UTILIZAÇÃO DE DADOS</h5>                 
               <br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               Os dados coletados pela Informezz, tem como finalidade cadastrar o cliente em nosso banco de dados para contato e registro de utilização dos serviços prestados. 
               Podendo com isso identificar o produto ou serviço adquirido pelo cliente e dar assistência e suporte necessários. Além de manter o cliente informado 
               mudanças e atualizações que possam ocorrer em nossos serviços. Possibilita também fornecer um atendimento personalizado.

               <br/><br/>
               <h5>7. COMPARTILHAMENTO DE DADOS COM TERCEIROS</h5>                 
               <br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               A Informezz trabalha e pode vir a trabalhar com empresas parceiras. Deste modo, em alguns casos, poderá ser necessário compartilhar as informações 
               coletadas com essas empresas. Tais empresas podem possuir políticas de privacidade próprias para coleta e manipulação de dados.

               <br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               Fica facultado o compartilhamento dos dados nas seguintes hipóteses:

               <br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               - Com outras empresas/filiais que fazem parte do grupo da Informezz, que possuem Políticas de Privacidade semelhantes às descritas na presente Política.

               <br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               - Com empresas parceiras, que sejam essenciais para funcionalidade dos serviços e atividades comerciais prestadas.

               <br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               - Solicitação judicial requerendo o acesso aos dados do Titular, ou para a proteção de seus interesses em conflitos de quaisquer espécies.

               <br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               A Informezz tem o cuidado para que nos casos em que seja necessário compartilhar seus dados, sempre o fará de acordo com a legislação brasileira vigente aplicável e com a adoção das melhores práticas de segurança da informação.

               <br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               Caso seja necessário o compartilhamento de outro meio que não esteja descrito neste item, só o faremos mediante o seu conhecimento e consentimento.

               <br/><br/>
               <h5>8. ARMAZENAMENTO E MANUTENÇÃO DOS DADOS</h5>

               <br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               Nossa empresa realiza o armazenamento das informações coletadas em suas páginas e servidores próprios, ou servidores contratados para esse fim.

               <br/><br/>
               <h5>9. TEMPO DE ARMAZENAMENTO</h5>
               <br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               Armazenamos os dados pelo período necessário para prestação dos nossos serviços contratados. Após esse período poderemos manter os dados necessários para manter em nossa base os registros e históricos dos serviços utilizados. Caso não tenha mais necessidade de armazenamento dos dados eles serão excluídos dos nossos registros e servidores de forma automática. Porém o proprietário das informações pode, a qualquer momento, solicitar a exclusão das informações. A exclusão será executada, exceto em casos que seja necessário para obrigação legal, regulatória ou judicial.

                </label>
            </Card>
        </Container>
        <Button 
                variant="contained" 
                color="primary" 
                onClick={voltarInicio}
                style={{ marginTop: '20px', marginBottom: '20px' }}
            >
                Voltar a Página Inicial
        </Button>        
    </>
    );
}

export default PoliticaDePrivacidade;
