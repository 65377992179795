import React from 'react';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import './drawerStyles.css';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

export default function PersistentDrawerRight() {  
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const scrollToContent = (id) => {
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: 'smooth' });    
    handleDrawerClose();
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={'rootDrawer'}>
      <CssBaseline />
      <AppBar
        style={{
            height: '100px',
            backgroundColor: 'transparent',
            boxShadow: 'none'
        }}
        position="absolute"
        className={clsx('appBar', {          
          'appBarShift': open,
        })}
      >
        <Toolbar >
          <Typography variant="h6" noWrap className={'title'}>
            
          </Typography>
          <IconButton
            style={{color: '#0E3383', marginTop: '20px'}}
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            className={clsx(open && 'hide')}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <main
        className={clsx('content', {
          ['contentShift']: open,
        })}
      >
        <div className={'drawerHeader'} />
      
      </main>
      <Drawer
        className={'drawer'}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: 'drawerPaper',
        }}
      >
        <div className={'drawerHeader'}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>       
        <Divider />
        <ListItem style={{cursor: 'pointer'}} onClick={() => scrollToContent("funcionalidades")} >
            <ListItemText primary="Funcionalidades" className={'drawerItemText'} />
        </ListItem>  

        <Divider />
        <ListItem style={{cursor: 'pointer'}} onClick={() => scrollToContent("sobre-nos")} >
            <ListItemText primary="Segmento" className={'drawerItemText'} />
        </ListItem>  

        <Divider />
        <ListItem style={{cursor: 'pointer'}} onClick={() => scrollToContent("sobre-nos")} >
            <ListItemText primary="Sobre Nós" className={'drawerItemText'} />
        </ListItem>  
        
        <Divider />
        <ListItem style={{cursor: 'pointer'}} onClick={() => scrollToContent("fale-conosco")} >
            <ListItemText primary="Fale Conosco" className={'drawerItemText faleConosco'} />
        </ListItem>  
        <ListItem style={{cursor: 'pointer'}} onClick={()=> navigate('/politica-de-privacidade')}>
            <ListItemText primary="Política de Privacidade" className={'drawerItemText'} />
        </ListItem>  

        </Drawer>
    </div>
  );
}
