import React from 'react';
import './stylesContentEcommerce.css';  // Verifique o caminho do arquivo CSS
import InfoAdicionais from '../InfoAdicionais/index.jsx';
import RedesSociais from '../RedesSociais/index.jsx';
import ManualBling from '../IntegracoesManualBling/index.jsx';

const ContentEcommerce = () => {
  return (
    <div className='content'>            
      <ManualBling />
      <InfoAdicionais />      
      <RedesSociais />
    </div>
  );
}

export default ContentEcommerce;
