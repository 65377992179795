import React from 'react';
import { Button } from '@mui/material';
import ArrowForward from '@mui/icons-material/ArrowForward';

const ButtonAbrirMapa = ({fontSize}) => {  

  const handleAbrirMapa = () => {
    let linkMapsInformezz= 'https://www.google.com/maps/place/Informezz+-+Sistemas+para+Lojas+de+Roupas+e+Calçados/@-26.2315417,-52.6731154,15z/data=!4m6!3m5!1s0x94e55368a5ce82bb:0x20438f139ddf0b2b!8m2!3d-26.2315417!4d-52.6731154!16s%2Fg%2F11v0mm2362?entry=ttu';
    window.open(linkMapsInformezz);
}   

   return (
    <>
    <div className='row' style={{marginTop: '3%'}}>      
        <label className="text" style={{ 
          fontSize: '20px', 
          fontWeight: '600',
          color: '#0E3383', 
          textAlign:'left', 
          cursor: 'pointer',           
          float:'left'}}
          onClick={handleAbrirMapa}>
          Ver no Mapa
          <Button style={{
              backgroundColor: 'white',
              borderRadius: '25px',
              marginLeft: '2%'              
          }}>
          <ArrowForward onClick={handleAbrirMapa}/>
        </Button>      
          </label>      
      </div>
    </>
  );
}

export default ButtonAbrirMapa;