import React from 'react';
import './stylesmanualecommerce.css'; 
import Toolbar from '../../components/ToolBar';
import ContentEcommerce from '../../components/ContentEcommerce';

const ManualEcommerce = () => {  
    return (
    <>
        <Toolbar habilitar={false}/>                        
        <ContentEcommerce/>
    </>
    );
}

export default ManualEcommerce;
