import React from 'react';
import VideoInstitucional from '../Institucional/index.jsx';
import TopoPrincipal from '../TopoPrincipal/index.jsx';  // Verifique o caminho do componente
import './styles.css';  // Verifique o caminho do arquivo CSS
import CardsFuncionalidades from '../CardsFuncionalidades/index.jsx';
import InfoAdicionais from '../InfoAdicionais/index.jsx';
import SolucoesInteligentes from '../SolucoesInteligentes/index.jsx';
import EntreEmContato from '../EntreEmContato/index.jsx';
import RedesSociais from '../RedesSociais/index.jsx';
import ButtonsFuncionalides from '../ButtonsFuncionalidades/index.jsx';
import Segmento from '../Segmento/index.jsx';

const Content = () => {
  return (
    <div className='content'>
      <TopoPrincipal /> 
      <VideoInstitucional />
      <ButtonsFuncionalides />      
      <CardsFuncionalidades />
      {/* <Segmento />     */}
      <EntreEmContato />      
      <InfoAdicionais />
      <RedesSociais />
    </div>
  );
}

export default Content;
