import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './entrecontato.css';

function EntreEmContato() {
  return (
    <div id="fale-conosco"  className="contact-section">
      <div className="contact-content">
        <div className="contact-image">
          <FontAwesomeIcon icon={faHeadset} size="4x" color="#0E3383" />
        </div>
        <div className="contact-text">
          <h1>Está com dúvida de como a Informezz pode ajudar na gestão de sua loja?</h1>
          <h4>Nosso time de especialistas está preparado para tirar todas as suas dúvidas</h4>
        </div>
      </div>
      <div className="central-text">
        <p>Central de Atendimento: (46) 3225-0131</p>
      </div>
      <div className="contact-buttons">
        <a href="https://wa.me/5546991107619" className="contact-button" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faWhatsapp} size="2x" className="button-icon" />
          <div className="button-text">
            <p>WhatsApp Comercial</p>
          </div>
        </a>
        <a href="https://wa.me/554632250131" className="contact-button" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faWhatsapp} size="2x" className="button-icon" />
          <div className="button-text">
            <p>Suporte Técnico</p>
          </div>
        </a>
      </div>
    </div>
  );
}

export default EntreEmContato;
