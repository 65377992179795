import { SocialIcon } from "react-social-icons";
import './redesSociaisStyles.css';

function RedesSociais() {
    return (
        <>        
         <div className="redes-sociais-container">                          
            <div className="icones">
                <SocialIcon network='instagram' url="https://www.Instagram.com/Informezz" bgColor="#0E3383"/>                   
            </div>                    
            <div className="icones">
                <SocialIcon network='facebook' url="https://www.facebook.com/people/Informezz-Sistemas/61557268662997/?mibextid=ZbWKwL" bgColor="#0E3383"/>  
            </div>    
            <div className="icones">
                <SocialIcon network='youtube' url="https://youtu.be/wzY6DGE1TcE" bgColor="#0E3383"/>  
            </div>               
        </div> 
        </>
    );
}

export default RedesSociais;
