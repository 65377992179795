import React from 'react'

import {
    HashRouter as Router,
        Route,
    Routes,    
    Navigate
} from "react-router-dom";
import PaginaPrincipal from './pages/Home';
import PoliticaDePrivacidade from './pages/PoliticaDePrivacidade';
import SocialMedia from './pages/SocialMedia';
import CentralDeInformacoes from './pages/CentralDeInformacoes';
import CentralDeInformacoesEtiquetas from './pages/CentralDeInformacoesEtiquetas';
import Obrigado from './pages/Obrigado';
import ManualEcommerce from './pages/ManualEcommerce';

const AppRoutes = () => {        
    return(
        <Router basename={process.env.PUBLIC_URL}>          
            <Routes>                             
              <Route
                path="/"
                element={<Navigate to="/home" />}
              />        
              <Route exact path="/home" element={<PaginaPrincipal />} />  
              <Route exact path="/politica-de-privacidade" element={<PoliticaDePrivacidade />} />
              <Route exact path="/contato" element={<SocialMedia />} />            
              {/* <Route exact path="/central-de-informacoes" element={<CentralDeInformacoes />} />  */}
              <Route exact path="/central-de-informacoes/etiquetas" element={<CentralDeInformacoesEtiquetas />} />
              <Route exact path="/obrigado" element={<Obrigado />} />                
              <Route exact path="/central-de-informacoes/manualecommerce" element={<ManualEcommerce />} />                                  
            </Routes>                            
      </Router>
    )
}
export default AppRoutes;