import React from 'react';
import { Button } from '@mui/material';

const ButtonSolicitarDemonstracao = ({ onClick }) => {
  return (
    <Button
      variant="contained" 
      color="primary" 
      onClick={onClick}
      sx={{
        width: '200px',
        paddingLeft: '24px',
        paddingRight: '24px',
        borderRadius: '8px',                     
        backgroundColor: '#AE8800',
        '&:hover': {
          backgroundColor: '#CD9900', // Altera para uma cor um pouco mais clara ao passar o mouse
        },
      }}
    >
      CONHEÇA GRÁTIS
    </Button>
  );
}

export default ButtonSolicitarDemonstracao;
