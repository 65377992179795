import React, { useRef, useEffect } from 'react';
import Toolbar from '../../components/ToolBar';
import { Card, Container } from '@mui/material';
import './styles.css';
import { Link } from 'react-router-dom';
import { Facebook, Instagram, Language, WhatsApp } from '@mui/icons-material';
import { useMediaQuery } from 'react-responsive';
import LogoInformezzVertical from '../../imagens/LogoInformezzVertical.png';

const Obrigado = () => {  
    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollIntoView({ behavior: 'smooth' });
          }
    }, []);    

    return (
    <>
        <Toolbar habilitar={false}/>         
        <div ref={containerRef} style={{padding:'1px'}}>
        </div>
        <Container>
            <Card style={{marginTop: '32%', padding: '20px'}}>       
                <h3>Obrigado!</h3>
                <h3>Em breve entraremos em contato.</h3>

                <Link to="https://www.informezz.com.br" style={{ textDecoration: 'none' }}>
                    <div className='card-obrigado' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>                        
                        <div style={{ backgroundColor: '#0E3383', padding: '8px 24px', borderRadius: '16px', marginTop: '8px' }}>
                            <p style={{ color: 'white', margin: '0', fontSize: '24px', fontWeight: 'bold', textAlign: 'center' }}>
                                Voltar ao Site
                            </p>
                        </div>
                    </div>
                </Link>
            </Card>
        </Container>        
    </>
    );
}

export default Obrigado;
