import React from 'react';
import './socialmediastyles.css';
import { Link } from 'react-router-dom';
import { Facebook, Instagram, Language, WhatsApp } from '@mui/icons-material';
import { useMediaQuery } from 'react-responsive';
import LogoInformezzVertical from '../../imagens/LogoInformezzVertical.png';
import { Card, Container } from '@mui/material';



const SocialMedia = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <Container style={{ width: "400px" }}>
      <Card>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'center', height: '100vh' }}>
          {/* Cabeçalho com a logo */}
          <div style={{ marginBottom: '60px' }}>
            <img src={LogoInformezzVertical} alt="Contate-nos" style={{ width: '80%', height: 'auto' }} />
          </div>

          {/* Título "Entre em Contato" com fundo #0E3383 ocupando 100% da altura */}
          <div style={{ backgroundColor: '#0E3383', padding: '30px', borderRadius: '5px', color: 'white', width: '100%', boxSizing: 'border-box' }}>
            <h2 style={{ margin: 0, marginLeft: -50, fontSize: '30px' }}>Entre em Contato</h2>
          </div>

          {/* Botões de mídia social */}
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '0px' }}>

          <Card style={{ marginTop: '10px', paddingLeft: '5px' }}>
              <Link to="https://wa.me/5546991107619" style={{ textDecoration: 'none' }}>
                <div className='card-socialmedia' style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ backgroundColor: '#25D366', padding: '10px', borderRadius: '100px' }}>
                    <WhatsApp style={{ width: '30px', height: '30px', color: 'white' }} />
                  </div>
                  <div style={{ marginLeft: '10px', textAlign: 'left' }}>
                    <p style={{ color: '#616568', margin: '0', fontSize: '18px', fontWeight: 'bold' }}>
                      WhatsApp
                    </p>
                    <p style={{ color: '#616568', margin: '0', fontSize: '16px', fontWeight: 'semi-bold', whiteSpace: 'nowrap' }}>
                      Comercial
                    </p>
                  </div>
                </div>
              </Link>
            </Card>

            <Card style={{ marginTop: '10px', paddingLeft: '5px' }}>
              <Link to="https://wa.me/554632250131" style={{ textDecoration: 'none' }}>
                <div className='card-socialmedia' style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ backgroundColor: '#25D366', padding: '10px', borderRadius: '100px' }}>
                    <WhatsApp style={{ width: '30px', height: '30px', color: 'white' }} />
                  </div>
                  <div style={{ marginLeft: '10px', textAlign: 'left' }}>
                    <p style={{ color: '#616568', margin: '0', fontSize: '18px', fontWeight: 'bold' }}>
                      WhatsApp
                    </p>
                    <p style={{ color: '#616568', margin: '0', fontSize: '16px', fontWeight: 'semi-bold', whiteSpace: 'nowrap' }}>
                      Central de Atendimento
                    </p>
                  </div>
                </div>
              </Link>
            </Card>


            <Card style={{ marginTop: '10px', paddingLeft: '5px' }}>
              <Link to="https://www.informezz.com.br" style={{ textDecoration: 'none' }}>
                <div className='card-socialmedia' style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ backgroundColor: '#616568', padding: '10px', borderRadius: '100px' }}>
                    <Language style={{ width: '30px', height: '30px', color: 'white' }} />
                  </div>
                  <div style={{ marginLeft: '10px', textAlign: 'left' }}>
                    <p style={{ color: '#616568', margin: '0', fontSize: '18px', fontWeight: 'bold' }}>
                      Site
                    </p>
                    <p style={{ color: '#616568', margin: '0', fontSize: '16px', fontWeight: 'semi-bold', whiteSpace: 'nowrap' }}>
                      www.informezz.com.br
                    </p>
                  </div>
                </div>
              </Link>
            </Card>


            <Card style={{ marginTop: '10px', paddingLeft: '5px' }}>
              <Link to="https://www.instagram.com/informezz/" style={{ textDecoration: 'none' }}>
                <div className='card-socialmedia' style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ backgroundColor: '#E4405F', padding: '10px', borderRadius: '100px' }}>
                    <Instagram style={{ width: '30px', height: '30px', color: 'white' }} />
                  </div>
                  <div style={{ marginLeft: '10px', textAlign: 'left' }}>
                    <p style={{ color: '#616568', margin: '0', fontSize: '18px', fontWeight: 'bold' }}>
                      Instragram
                    </p>
                    <p style={{ color: '#616568', margin: '0', fontSize: '16px', fontWeight: 'semi-bold', whiteSpace: 'nowrap' }}>
                      instagram.com/informezz
                    </p>
                  </div>
                </div>
              </Link>
            </Card>

            <Card style={{ marginTop: '10px', paddingLeft: '5px' }}>
              <Link to="https://www.facebook.com/informezzoficial/" style={{ textDecoration: 'none' }}>
                <div className='card-socialmedia' style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ backgroundColor: '#1877F2', padding: '10px', borderRadius: '100px' }}>
                    <Facebook style={{ width: '30px', height: '30px', color: 'white' }} />
                  </div>
                  <div style={{ marginLeft: '10px', textAlign: 'left' }}>
                    <p style={{ color: '#616568', margin: '0', fontSize: '18px', fontWeight: 'bold' }}>
                      Facebook
                    </p>
                    <p style={{ color: '#616568', margin: '0', fontSize: '16px', fontWeight: 'semi-bold', whiteSpace: 'nowrap' }}>
                      facebook.com/informezzoficial
                    </p>
                  </div>
                </div>
              </Link>
            </Card>
          </div>
        </div>
      </Card>
    </Container>
  );
};

export default SocialMedia;
