import { useState, useEffect } from 'react';
import { Card } from "@mui/material";
import LogoInformezzVertical from '../../imagens/LogoInformezzVerticalPeq.png';
import PlayStroe from '../../imagens/PlayStore.png';
import AppStore from '../../imagens/AppStore.png';
import ButtonAbrirMapa from "../Buttons/ButtonAbrirMapa";
import { useNavigate } from 'react-router-dom';
import './infoAdicionaisStyles.css';

const InfoAdicionais = () => {         
  const [ajusteTelaReduzida, SetAjusteTelaReduzida] = useState(false);    
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const navigate = useNavigate();

  const toggleMenu = () => {
    if (window.innerWidth < 600) {
      SetAjusteTelaReduzida(true);
    } else {
      SetAjusteTelaReduzida(false);
    }
  };

  useEffect(() => {
    toggleMenu(); 
    window.addEventListener('resize', toggleMenu);
    return () => {
      window.removeEventListener('resize', toggleMenu);
    };
  }, []);    

  return (        
    <div className="info-adicionais-container">   
      <div className="info-adicionais-card">
        <div className="info-adicionais-coluna1">
          <img src={LogoInformezzVertical} alt="Logo" className="logo" />
          <h5 className="texto-h5-institucional">Com mais de vinte anos de experiência, somos uma empresa dedicada a potencializar lojas de roupas e calçados por meio de soluções tecnológicas inovadoras.</h5>
          <h5 className="texto-h5-copyright">Copyright ©{currentYear} Direitos Reservados - Informezz</h5>
        </div>
        
        <div className="info-adicionais-coluna2">
          <h5 className="texto-h5-instituicao">INFORMAÇÕES E AJUDA</h5>
          <h5 className="texto-central-atendimento">Central de Atendimento: (46) 3225-0131</h5>
          <button className="botao" onClick={() => navigate('/politica-de-privacidade')}>Política de Privacidade</button>
        </div>
        
        <div className="info-adicionais-coluna3">
          <h5 className="texto-h5-matriz">INFORMEZZ SISTEMAS </h5>
          <h5 className="texto-h5-endereco">Endereço: Rua Xingu, 61 - Centro</h5>
          <h4 className="texto-h5-cidade">Pato Branco - PR</h4>
          <ButtonAbrirMapa className="botao-mapa" fontSize={ajusteTelaReduzida ? 20 : 24} /> 
        </div>
        
        <div className="info-adicionais-coluna4">
          <h5 className="texto-h5-matriz">APLICATIVOS</h5>
          <img 
            src={PlayStroe} 
            alt="App 1" 
            className="imagem-app" 
            onClick={() => window.open('https://play.google.com/store/apps/details?id=com.informezz.mascapp', '_blank')} 
          />
          <img 
            src={AppStore} 
            alt="App 2" 
            className="imagem-app" 
            onClick={() => window.open('https://apps.apple.com/br/app/masc/id6471785383', '_blank')} 
          />
        </div>
      </div>
    </div>        
  );
};

export default InfoAdicionais;
