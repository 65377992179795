import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Container } from 'react-bootstrap';
import './stylesinstitucional.css'; // Certifique-se de que o nome está correto

const VideoInstitucional = () => {
  const [ajusteTelaReduzida, setAjusteTelaReduzida] = useState(false);

  const toggleMenu = () => {
    if (window.innerWidth < 1200) {
      setAjusteTelaReduzida(true);
    } else {
      setAjusteTelaReduzida(false);
    }
  };

  useEffect(() => {
    toggleMenu();
    window.addEventListener('resize', toggleMenu);
    return () => {
      window.removeEventListener('resize', toggleMenu);
    };
  }, []);

  return (
    <div id="sobre-nos" className='video-section'>
      <h1 className='video-title-h1'>São mais de 20 anos de experiência oferecendo soluções tecnológicas para a gestão do varejo de moda.</h1>
      <h1 className='video-title-h2'>Convidamos você a conhecer mais sobre nós assistindo ao vídeo abaixo.</h1>
      <Container className="video-institucional-container">
        <div className="video-institucional">
          <ReactPlayer
            url='https://youtu.be/wzY6DGE1TcE'            
            width="100%"
            height={ajusteTelaReduzida ? '360px' : '720px'}
            controls
          />
        </div>
      </Container>
    </div>
  )
}

export default VideoInstitucional;
