import React, { useRef, useEffect } from 'react';
import Toolbar from '../../components/ToolBar';
import { Card, Container } from '@mui/material';
import ButtonVoltarHome from '../../components/Buttons/ButtonVoltarHome';
import './stylesManualBling.css';
import blingConfiguracoes from '../../imagens/integracoes/bling_configuracoes.png';
import blingCadastro from '../../imagens/integracoes/bling_cadastro.png';
import blingCadastroApp from '../../imagens/integracoes/bling_cadastroapp.png';
import blingCadastroAppSelecao from '../../imagens/integracoes/bling_cadastroappselecao.png';
import blingCadastroAppEscopo from '../../imagens/integracoes/bling_cadastroappescopo.png';
import mascConfiguracoes from '../../imagens/integracoes/masc_configuracoes.png';
import mascConfiguracoesEcommerce from '../../imagens/integracoes/masc_configuracoesecommerce.png';
import blingDados from '../../imagens/integracoes/bling_dados.png';

const ManualBling = () => {  
    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    const voltarInicio = () => {
        window.location.href = 'https://www.informezz.com.br';
    };

    return (
        <div className='principal'>
            <Toolbar habilitar={false}/>
            <div ref={containerRef} style={{ padding: '0px' }}></div>
            <div className='top-section'>
                <ButtonVoltarHome 
                    text="Voltar" 
                    onClick={voltarInicio} 
                />
            </div>
            <Container className='container'>
                <Card> 
                    <h1 className="contact-text">Manual de Integração com Bling</h1>                                       
                    <label className='contact-text'>   
                        <h5 className='texto-h5'>Passo a passo para configurar o aplicativo MASC no BLING</h5> 
                        <br/>
                        <h6 className='texto-h6'>1. Acesse o Bling e faça o login na sua conta.</h6>                                                               
                        <br/>
                        <h6 className='texto-h6'>2. No canto superior direito passe o mouse sobre a engrenagem e clique na opção "Todas as configurações".</h6>                                       
                        <img src={blingConfiguracoes} className="imagem-manual" />
                        <br/><br/>
                        <h6 className='texto-h6'>3. No menu lateral clique na opção "Cadastros" e depois clique na opção "Cadastro de aplicativos".</h6>                                                          
                        <img src={blingCadastro} className="imagem-manual" />
                        <br/><br/>
                        <h6 className='texto-h6'>4. Na tela de Cadastro de aplicativo, clique em "CRIAR NOVO APLICATIVO".</h6>
                        <img src={blingCadastroApp} className="imagem-manual" />
                        <br/><br/>
                        <h6 className='texto-h6'>5. Em "Tipo do aplicativo", selecione a opção "Tenha acesso à API v3 do Bling!"<br/>&nbsp;&nbsp;&nbsp;
                        Depois em "Selecione o uso do aplicativo", selecione a opção "Privado" e em seguida clique em "Próximo".
                        </h6>
                        <img src={blingCadastroAppSelecao} className="imagem-manual" />
                        <br/><br/>
                        <h6 className='texto-h6'>6. Na tela "Insira os dados do seu aplicativo" preencha os dados obrigatórios conforme documento encaminhado no ato da contratação<br/>
                        <br/>&nbsp;&nbsp;&nbsp;
                        Dados Obrigatórios:<br/>&nbsp;&nbsp;&nbsp;
                        - Nome<br/>&nbsp;&nbsp;&nbsp;
                        - Categoria<br/>&nbsp;&nbsp;&nbsp;
                        - Link de redirecionamento<br/>&nbsp;&nbsp;&nbsp;
                        - Link da homepage<br/>&nbsp;&nbsp;&nbsp;
                        - Nome do desenvolvedor<br/>&nbsp;&nbsp;&nbsp;
                        - Email<br/>&nbsp;&nbsp;&nbsp;
                        - Celular<br/>&nbsp;&nbsp;&nbsp;
                        </h6>
                        <br/>
                        <h6 className='texto-h6'>7. Na sequencia em "Selecione os escopos", adicione as opções conforme documento encaminhado no ato da contratação,  
                        e clique em "Salvar".&nbsp;&nbsp;&nbsp;              
                        </h6>                        
                        <img src={blingCadastroAppEscopo} className="imagem-manual" />
                        <br/><br/>
                        <h6 className='texto-h6'>8. O próximo passo é acessar o sistema MASC com seu Login e Senha, navegar no menu lateral em: <br/>&nbsp;&nbsp;&nbsp;
                        Configuração > Integrações > E-Commerce
                        </h6>
                        <img src={mascConfiguracoes} className="imagem-manual" />
                        <br/><br/>
                        <h6 className='texto-h6'>9. Na tela de "Integração E-Commerce" informe os campos "Client ID" e "Cliente Secret", essas informações estão 
                            na tela de cadastro de aplicativo do Bling! Após incluir as informações clique no botão "Autenticar".<br/>&nbsp;&nbsp;&nbsp;
                        </h6>                   
                        <img src={blingDados} className="imagem-manual" />
                        <img src={mascConfiguracoesEcommerce} className="imagem-manual" />
                        <br/><br/>
                        <h6 className='texto-h6'>10. Após isso, você será redirecionado para o site do Bling, sendo necessário fazer o login e senha para concluir.
                            Após isso estará tudo pronto, e você já poderá começar a sincronizar os produtos com o Bling.<br/>&nbsp;&nbsp;&nbsp;
                        </h6>
                    </label>
                </Card>
            </Container>
            <div className='bottom-section'>
                <ButtonVoltarHome 
                    text="Voltar" 
                    onClick={voltarInicio} 
                />
            </div>
        </div>
    );
};

export default ManualBling;
